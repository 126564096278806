<template>
  <div class="container-fluid text-left">

    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label">Group Name</label>
          <input class="form-control" v-model="group.groupName"  type="text" @keyup="isDirty=true"/>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
         <label for="groupType">Group Type</label>
         <select class="form-control" id="groupType" v-model="group.groupType" @change="isDirty=true">
           <option value="-1">Select...</option>
           <option value="0">DME Vendor</option>
           <option value="1">Clinic</option>
           <option value="2">Other</option>
         </select>
       </div>
       </div>
    </div>

    <div class="text-center">
      <h4 class="mt-2">Permissions</h4>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="form-group">
         <label for="groupType">Demographics</label>
          <select class="form-control"  v-model="group.demographicsLevel" @change="isDirty=true">
            <option value="-1">Select...</option>
            <option value="0">Hidden</option>
            <option value="1">Read Only</option>
            <option value="2">Update</option>
          </select>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
         <label for="groupType">Clinical</label>
          <select class="form-control"  v-model="group.clinicalLevel" @change="isDirty=true">
            <option value="-1">Select...</option>
            <option value="0">Hidden</option>
            <option value="1">Read Only</option>
            <option value="2">Update</option>
          </select>
        </div>
      </div>

      <div class="col-4">
        <div class="form-group">
         <label for="groupType">Device</label>
          <select class="form-control"  v-model="group.deviceLevel" @change="isDirty=true">
            <option value="-1">Select...</option>
            <option value="0">Hidden</option>
            <option value="1">Read Only</option>
            <option value="2">Update</option>
          </select>
        </div>
      </div>
    </div>

    <div class="row text-center">
      <div class="col-4">
        <button class="btn nw-btn-outline-info" @click="cancel()" v-show="group.oid < 0">Cancel</button>
      </div>
      <div class="col-4">
        <button class="btn nw-btn-success" @click="save()" :disabled="isDirty==false">Save</button>
      </div>
    </div>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'

export default {
  props: ['group'],

  data () {
    return {
      isDirty: false
    }
  },
  methods: {
    save () {
      NetClient.doRequest("rest/user", { action: 'group', group: this.group}).then(msg => {
        if(typeof msg.data != 'undefined') this.$emit('success', msg.data)
      })
    },
    cancel () {
        this.$emit('cancel')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
