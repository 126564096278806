<template>
  <div>
    <div class="row mt-2">
      <div class="col-4">
        <div class="form-group">
          <label class="control-label">Group Name</label>
          <input class="form-control" v-model="model.name"  type="text"/>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="btn nw-btn-outline-info mr-3" @click="cancel()" v-show="showCancel=='true'">Cancel</button>
      <button class="btn nw-btn-outline-info" @click="search()"><i class="fa fa-search"></i> Search</button>
    </div>
    <table class="table table-hover mt-4">
      <thead><tr><th>Group Name</th><th>Group Type</th></tr></thead>
      <tbody>
        <tr v-for="item in arr" @click="select(item.oid)">
          <td>{{ item.groupName}}</td><td>{{ groupType(item.groupType)}}</td>
        </tr>
      </tbody>

    </table>
  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'
import Util from '@/util/util.js'

export default {
  props: ['showCancel'],
  data () {
    return {
      model: {
        action: 'searchGroup',
        name: ''
      },
      arr: []
    }
  },
  methods: {
    groupType: function(_type) {
      switch(_type)
      {
        case 0:
          return 'DME Vendor'
        case 1:
          return 'Clinic'
        case 2:
          return "Other"
        default:
          return "Unknown"
      }
    },
    search () {
        NetClient.doRequest('rest/user', this.model).then(msg => {
          if(typeof msg.data != 'undefined') this.arr = msg.data
        })
    },
    select (_uid) {
      var group = Util.getObjectByID(this.arr, _uid)
      if(null != group)
      {
        //this.$router.push({ name: 'UserGroup', params: { oid: group.oid }})
        this.$emit('select', group)
      }
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
