var Util = {
  getObjectByID: function(_arr, _id)
	{
		if(null == _arr) return null
		var ii = 0

		for(ii=0;ii<_arr.length;ii++) if(typeof _arr[ii].oid != 'undefined') if(_arr[ii].oid == _id) return _arr[ii]
		return null
	},
  removeObject: function(_arr, _id)
	{
		if(null == _arr) return
		var ii = 0

		for(ii=0;ii<_arr.length;ii++) if(typeof _arr[ii].oid != 'undefined') if(_arr[ii].oid == _id)
    {
      _arr.splice(ii,1)
      return
    }
	},
	replaceObject: function(_arr, _obj)
	{
		if(null == _arr) return
		var ii = 0

		for(ii=0;ii<_arr.length;ii++)
		{
		   if(_arr[ii].oid == _obj.oid)
		   {
		       _arr[ii] = _obj
		       return
		   }
		}

		_arr.push(_obj)

		return
	}
}

export default Util
