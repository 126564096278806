<template>
  <div class="container-fluid">
    <div class="card nw-card mt-2">
      <div class="card-header nw-card-header text-left">
        <h4>Admin Console User Groups</h4>
      </div>
      <div class="card-body text-left">
        <div v-show="activePage=='search'">
          <button class="btn nw-btn-outline-info" @click="newGroup()"><i class="fa fa-plus"></i> Create New Group<br/></button>
          <group-search @select="success"></group-search>
        </div>
        <div v-show="activePage=='group'">
          <h3> Create New User Group</h3>
          <user-group :group="selGroup" @cancel="cancel" @success="success"></user-group>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupSearch from '@/components/UserGroupSearch'
import UserGroup from '@/components/UserGroup'

export default {
  components: {GroupSearch, UserGroup },
  data () {
    return {
      activePage: 'search',
      selGroup: this.emptyGroup()
    }
  },

  methods: {
    emptyGroup () {
      return {
        oid: -1,
        groupName: '',
        groupType: 2
      }
    },
    newGroup () {
      this.selGroup = this.emptyGroup()
      this.activePage = 'group'
    },

    cancel() {
      this.activePage = 'search'
    },
    success (_group) {
      this.$router.push({ name: 'UserGroup', params: { oid: _group.oid }})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
